import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import RichText from "../components/richtext"
import SEO from "../components/seo"

import { formatHours, formatDate } from "../utils/data"

const EventTemplate = ({ data }) => {
  const {
    name,
    date,
    event_type,
    location,
    image,
    description,
  } = data.prismicEvent.data
  return (
    <Layout>
      <SEO title={name.text} description={description.text} />
      <div className="max-w-6xl mx-auto w-full px-4 py-10 flex flex-col md:flex-row-reverse">
        <div className="md:w-1/2 md:pl-4">
          <p>{event_type}</p>
          <h1 className="mb-8">{name.text}</h1>
          <p>
            {formatDate(date)} at {formatHours(date)}
          </p>
          {location && <p>{location}</p>}
          {description.html && <RichText html={description.html} />}
        </div>
        <div className="w-full md:w-1/2">
          <Img fluid={image.fluid} className="w-full" />
        </div>
      </div>
    </Layout>
  )
}

export default EventTemplate

export const query = graphql`
  query EventByUID($uid: String!) {
    prismicEvent(uid: { eq: $uid }) {
      data {
        name {
          text
        }
        date
        event_type
        location
        image {
          fluid(maxWidth: 1024) {
            ...GatsbyPrismicImageFluid
          }
        }
        description {
          html
          text
        }
      }
    }
  }
`
